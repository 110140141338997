import * as React from "react";
import DefaultLayout from "../layouts/default";
import { SinglePostWrap } from "../components/LayoutComponents.stc";

import { SRLWrapper } from "simple-react-lightbox";

import { ArtType, BildType, KontinentLandType } from "../components/types";
import styled from "styled-components";
import {
  Row,
  Col,
} from "reactstrap";
import _ from "lodash";
import { Helmet } from "react-helmet";


const ArticleImage = styled.img`
  width: 100%;
  padding-bottom: 30px;
`;

interface StyledHeroImageProps {
  bgsrc: string;
}

const HeroImage = styled.div<StyledHeroImageProps>`
  background-image: url(${(props) => props.bgsrc});
  height: 40vh;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center center;
`;

interface ArtProps {
  pageContext: {
    art: ArtType;
  };
}

const Art: React.FC<ArtProps> = (props) => {
  const {
    pageContext: {
      art: { title, allgemeineInformationen, nameDeutsch, kennzeichenUndGrosse, lebensraumUndVerbreitung, zusatzinformationen, bilder, kontinentLandWahlen  },
    },
  } = props;

  let description = '';
  let pageTitle = title!;

  if(allgemeineInformationen){
    description = allgemeineInformationen;
  }

  if(nameDeutsch){
    pageTitle = pageTitle + ' (' + nameDeutsch + ")";
  }

  pageTitle = pageTitle + " - Insects";

  return (
    <DefaultLayout>
    <Helmet
      title={pageTitle}
      meta={[
        {
          name: "description",
          content: description,
        },
      ]}
    ></Helmet>
      <SinglePostWrap>
        {bilder[0]?.url && (
          <HeroImage bgsrc={bilder[0].url + "?w=530"} />
        )}
        <h1>{title!} {nameDeutsch && (<>({nameDeutsch})</>)}</h1>
        {kontinentLandWahlen && kontinentLandWahlen.map((kontinentLand: KontinentLandType) => (<>{kontinentLand.title}<br /></>))}
        
        {allgemeineInformationen && (<>
                                      <h2>Allgemeine Infos</h2>
                                      <div dangerouslySetInnerHTML={{ __html: allgemeineInformationen }}></div>
                                     </>)}
        
        {kennzeichenUndGrosse && (<>
                                    <h2>Kennzeichen und Grösse</h2>
                                    <div dangerouslySetInnerHTML={{ __html: kennzeichenUndGrosse }}></div>
                                  </>)}
        
        {lebensraumUndVerbreitung && (<>
                                        <h2>Lebensraum und Verbreitung</h2>
                                        <div dangerouslySetInnerHTML={{ __html: lebensraumUndVerbreitung }}></div>
                                      </>)}
        <br />
        {zusatzinformationen && (<div dangerouslySetInnerHTML={{ __html: zusatzinformationen }}></div>)}
        <h2>weitere Bilder zu dieser Art</h2>
      </SinglePostWrap>
      <SRLWrapper><Row>
        {bilder && bilder.map((bild: BildType) => (
            <Col xs="12" sm="6" md="4" key={bild.url}>
              <ArticleImage src={bild.url} />
            </Col>
        ))}
       
      </Row></SRLWrapper>
    </DefaultLayout>
  );
};

export default Art;
